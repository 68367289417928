import React from 'react';
import classNames from 'classnames';

import Hamburger from './hamburger.inline.svg';
import { Link } from 'gatsby';

export class Navigation extends React.Component {
  state = {
    open: false
  }

  get className () {
    return classNames({
      'navigation': true,
      'navigation--open': this.state.open
    });
  }

  componentDidMount = () => {
    let links = this.refs.links.querySelectorAll('a');
    Array.from(links).forEach(link => {
      link.addEventListener('click', this.close);
    })
  }

  toggle = () => {
    this.setState({ open: !this.state.open });
  }

  close = () => {
    this.setState({ open: false });
  }

  render = () => (
    <div ref="links">
      <nav className={this.className} >
        <div className="navigation__inner">
          <div className="navigation__hamburger" onClick={this.toggle}>
            <Hamburger />
          </div>
          <div className="navigation__logo">
            <Link to={this.props.logoLink}>Stable</Link>
          </div>
          <div className="navigation__book-now">
            <Link className="button" to={this.props.buttonTarget}>
              {this.props.buttonLabel}
            </Link>
          </div>
        </div>
      </nav>
      <div className="navigation__overlay">
        <div className="navigation__overlay__links">
          { this.props.items.map(link => <Link to={link.target}>{link.label}</Link>) }
        </div>
      </div>
    </div>
  )
}