import React from 'react';
import { Helmet } from 'react-helmet';
import { AsyncCss } from '../components/async-css';
import { Navigation } from '../components/navigation';

import './index.scss';

export default class Layout extends React.Component {
  constructor (props) {
    super(props);
    this.state = {};
  }

  render = () => (
    <>
      <Helmet>
        <meta name="description" content="In between goals there is life that has to be lived and enjoyed." />
        <meta property="og:image" content="" />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="630" />
        <meta name="keywords" content="stable, restaurant" />
        <link rel="icon" type="image/png" href="favicon-32x32.png" sizes="32x32" />
      </Helmet>

      <AsyncCss url="//fast.fonts.net/cssapi/de91795f-5f47-4311-a8e5-16bc5034b9b4.css" />
      <AsyncCss url="//fonts.googleapis.com/css2?family=Asul:wght@400;700&display=swap" />
      
      { this.props.children }
    </>
  )
}